import { Link } from 'gatsby'
import React from 'react'
import ReactSidebar from 'react-sidebar'
import styled from 'styled-components'
import { Icon } from 'react-icons-kit'
import {thList} from 'react-icons-kit/fa/thList'
import {install} from 'react-icons-kit/entypo/install'

const mql = typeof window !== 'undefined' && window.matchMedia(`(min-width: 800px)`)

const MenuItem = styled.div`
  font-size: 13px;
  margin-bottom: 8px;
`

const StyledLink = styled(Link)`
  color: #ddd;
  text-decoration: none;
  padding: 6px 7px 6px 18px;
  border-left: 4px solid #293f50;
  
  &:hover, &.active {
    color: #fff;
    border-left: 4px solid lightblue;
  }
`

const sidebarStyle = {
  sidebar: {
    padding: '5em 1.5em 1em 0',
    background: '#293f50',
    borderRight: '0 none',
    color: 'white',
  }
}

const MenuIcon = styled(Icon)`
    margin-right: 8px;
`

const Menu = () => (
  <>
    <MenuItem>
      <StyledLink
        to='/app/dashboard/'
        activeClassName='active'
      >
        <MenuIcon icon={thList} />
        <span>Dashboard</span>
      </StyledLink>
    </MenuItem>
    <MenuItem>
      <StyledLink
        to='/app/installation/'
        activeClassName='active'
      >
        <MenuIcon icon={install} />
        <span>Installation</span>
      </StyledLink>
    </MenuItem>
  </>
)


export class Sidebar extends React.Component {
  state = { sidebarDocked: true,
            sidebarOpen: true
          }

  UNSAFE_componentWillMount = () => {
    if (mql) {
      mql.addListener(this.mediaQueryChanged)
    }
  }

  componentWillUnmount = () => {
    if (mql) {
      mql.removeListener(this.mediaQueryChanged)
    }
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open })
  }

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: true, sidebarOpen: true })
  }

  render() {
    return (
      <ReactSidebar
        styles={sidebarStyle}
        transitions={false}
        sidebar={<Menu />}
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
      >
        { this.props.children }
      </ReactSidebar>
    )
  }
}

export default Sidebar