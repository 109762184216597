import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import HeaderAdmin from './header-admin'
import Sidebar from './sidebar'
import './layout.css'


const ContentWrapper = styled.div`
  margin: 0 auto;
  maxWidth: 1280;
  padding: 10px 0 0 10px;
  overflow: scroll;
  width: 100%;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: "description",
              content: "Extension Monitor - Prevent private data leaks.",
            },
            {
              name: "keywords",
              content:
                "extensions, security, browser, chrome",
            },
          ]}
          link={[
          ]}
          defer={false}
        />
        <Sidebar>
          <HeaderAdmin siteTitle={data.site.siteMetadata.title} />
          <ContentWrapper>
            {children}
          </ContentWrapper>
        </Sidebar>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
