import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'


const HeaderMain = styled.div`
  display: flex;
  background: #293f50;
  height: 60px;
  box-shadow: 0 2px 5px #999;
`

const LogoWrapper = styled.div`
  flex: 1 0 auto;
  padding: 20px 1.0875rem;
`

const Logo = styled.h4`
  margin: 0; 
  font-weight: 300;
`

const HeaderAdmin = ({ siteTitle }) => (
  <HeaderMain>
    <LogoWrapper>
      <Logo>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </Logo>
    </LogoWrapper>
  </HeaderMain>
)

HeaderAdmin.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderAdmin.defaultProps = {
  siteTitle: '',
}

export default HeaderAdmin
